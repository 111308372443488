<template>
  <b-card>
    <b-modal
      id="mapping-modal"
      ref="modal"
      no-close-on-backdrop
      :title="'Mapping Department Designation'"
      :ok-title="addBTN ? 'Create' : 'Update'"
      cancel-variant="outline-secondary"
      @ok.prevent="formSubmit"
    >
      <form
        ref="form"
        @submit.prevent
      >
        <!-- Add Document -->
        <b-row>
          <b-col
            cols="11"
            md="11"
            class="mb-md-0 mb-2"
          >
            <b-row>
              <b-col>
                <b-form-group
                  label="Short Name"
                  invalid-feedback="Short Name is required"
                  :state="shortNameState"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="shortName"
                    placeholder="Short Name"
                    type="text"
                    :state="shortNameState"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Role Designation"
                  invalid-feedback="Role Designation is required"
                  :state="roleDesignationState"
                  label-cols-md="4"
                >
                  <b-form-input
                    v-model="roleDesignation"
                    placeholder="Role Designation"
                    type="text"
                    :state="roleDesignationState"
                    required
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Id Card Type"
                  invalid-feedback="Id Card Type is required"
                  :state="idCardState"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="idCard"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Select Card Type"
                    label="name"
                    :options="idCardLists"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  label="Department"
                  invalid-feedback="Department is required"
                  :state="departmentState"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="department"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Select Department"
                    label="name"
                    :options="departmentLists"
                    :reduce="(val) => val.id"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="addBTN">
              <b-col>
                <b-form-group
                  label="Designation"
                  invalid-feedback="Designation is required"
                  :state="designationState"
                  label-cols-md="4"
                >
                  <v-select
                    v-model="designation"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Select Designation"
                    label="name"
                    :options="designationLists"
                    :reduce="(val) => val.oisacl_role_id"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </form>
    </b-modal>
    <div class="d-flex justify-content-between">
      <p class="card-title">
        Department Desiganation Mapping
      </p>
    </div>
    <div class="custom-search d-flex justify-content-between">
      <b-form-group>
        <b-button
          variant="outline-primary"
          @click="AddBtnModal"
        >
          Add Mapping
        </b-button>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      theme="my-theme"
    >
      <template
        slot="table-row"
        slot-scope="props"
      >
        <!-- Column: sl -->
        <span v-if="props.column.field === 'sl'">
          <span class="d-flex">
            <p>{{ props.index + 1 }}</p>
          </span>
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <span class="d-flex">
            <p v-if="props.row.status == 1">Active</p>
            <p v-else>Inactive</p>
          </span>
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'document_for'">
          <span class="d-flex">
            <p v-if="props.row.document_for == 1">Student</p>
            <p v-else>Employee</p>
          </span>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span class="d-flex">
            <b-button
              class="mr-1 px-1"
              variant="outline-warning"
              @click="editRow(props.row)"
            >Edit</b-button>
            <!--  <b-button
              class="px-1"
              variant="outline-danger"
              @click="deleteDocumentType(props.row)"
            >Delete</b-button> -->
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
        slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['10', '20', '30', '40', '50']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card>
</template>
<script>
import {
  BCard,
  BPagination,
  BFormInput,
  // BFormRadio,
  BFormGroup,
  BFormSelect,
  BRow,
  BCol,
  VBModal,
  BButton,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'vue-good-table/dist/vue-good-table.css'
import OisaclRoleService from '@/@service/api/administration/OisaclRole.service'
import vSelect from 'vue-select'
import FemsToastrService from '@/@service/utils/FemsToastr.service'
// import FemsToastrService from '@/@service/utils/FemsToastr.service'
export default {
  components: {
    BCard,
    VueGoodTable,
    BPagination,
    BFormInput,
    // BFormRadio,
    BFormGroup,
    BFormSelect,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      pageLength: 10,
      dir: false,

      shortName: '',
      roleDesignation: '',
      idCard: '',
      department: '',
      designation: '',

      shortNameState: null,
      roleDesignationState: null,
      idCardState: null,
      departmentState: null,
      designationState: null,

      idCardLists: [
        { id: 1, name: 'Management' },
        { id: 2, name: 'Admin' },
        { id: 3, name: 'Academic' },
        { id: 4, name: 'Staff' },
        { id: 11, name: 'Student' },
        { id: 21, name: 'Parent' },
      ],
      departmentLists: [],
      designationLists: [],
      columns: [
        {
          label: 'SL',
          field: 'sl',
        },
        {
          label: 'Role Name',
          field: 'oisacl_role_name',
        },
        {
          label: 'Short Name',
          field: 'short_name',
        },
        {
          label: 'Role Designation',
          field: 'oisacl_role_designation',
        },
        {
          label: 'Card Type',
          field: 'id_card.type',
        },
        {
          label: 'Department',
          field: 'department.name',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],

      updateId: '',
      addBTN: true,
    }
  },
  mounted() {
    this.readData()
  },
  methods: {
    readData() {
      OisaclRoleService.readData().then(response => {
        this.rows = response.data.data
      })
    },
    getDept() {
      OisaclRoleService.getDept().then(response => {
        this.departmentLists = response.data.data
      })
    },
    getDesignation() {
      OisaclRoleService.getDesignation().then(response => {
        this.designationLists = response.data.data
      })
    },
    checkFormValidity(addCheck) {
      this.shortNameState = null
      this.roleDesignationState = null
      this.idCardState = null
      this.departmentState = null
      if (!this.shortName) {
        this.shortNameState = false
      }
      if (!this.roleDesignation) {
        this.roleDesignationState = false
      }
      if (!this.idCard) {
        this.idCardState = false
      }
      if (!this.department) {
        this.departmentState = false
      }
      if (addCheck) {
        this.designationState = null
        if (!this.designation) {
          this.designationState = false
        }
        if (this.shortNameState !== false && this.roleDesignationState !== false && this.idCardState !== false
        && this.departmentState !== false && this.designationState !== false) {
          return true
        }
        return false
      }

      if (this.shortNameState !== false && this.roleDesignationState !== false && this.idCardState !== false
        && this.departmentState !== false) {
        return true
      }
      return false
    },

    AddBtnModal() {
      this.addBTN = true
      this.getDept()
      this.getDesignation()
      this.resetForm()
      this.$bvModal.show('mapping-modal')
    },

    editRow(rowData) {
      this.addBTN = false
      this.getDept()
      this.getDesignation()
      this.resetForm()
      console.log(rowData)
      /*
      shortName: '',
      roleDesignation: '',
      idCard: '',
      department: '',
      */
      this.updateId = rowData.oisacl_role_id
      console.log(this.updateId)
      this.shortName = rowData.short_name
      this.roleDesignation = rowData.oisacl_role_designation
      this.idCard = rowData.id_card.id
      this.department = rowData.department.id
      this.designation = rowData.oisacl_role_id
      this.$nextTick(() => {
        this.$bvModal.show('mapping-modal')
      })
    },

    formSubmit() {
      if (!this.checkFormValidity(this.addBTN)) {
        return
      }
      console.log(this.updateId)
      const mapData = {
        oisacl_role_name: this.roleDesignation,
        short_name: this.shortName,
        id_card_type: this.idCard,
        department_id: this.department,
        designation_id: this.designation,
      }
      console.log(mapData)
      OisaclRoleService.mappingUpdate(mapData, this.updateId ? this.updateId : this.designation).then(res => {
        if (res.data.status) {
          FemsToastrService.success(res.data.message)
          this.$bvModal.hide('mapping-modal')
          this.readData()
          this.resetForm()
        }
      })
    },

    resetForm() {
      this.shortNameState = null
      this.roleDesignationState = null
      this.idCardState = null
      this.departmentState = null
      this.designationState = null

      this.editData = false
      this.shortName = ''
      this.roleDesignation = ''
      this.idCard = ''
      this.department = ''
      this.designation = ''
      this.updateId = ''
    },
  },
}
</script>
