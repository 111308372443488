import axiosIns from '@/libs/axios'

export default {
  readData: (() => axiosIns.get('oisacl-role/read')),
  getDept: (() => axiosIns.get('oisacl-role/department')),
  getDesignation: (() => axiosIns.get('oisacl-role/designation')),
  mappingUpdate: (payload, updateId) => axiosIns.put(`oisacl-role/mapping-update/${updateId}`, payload),
/*   create: (payload => axiosIns.post('accounting/acc-advance-overrides', payload)),
  getOne: (id => axiosIns.get(`accounting/acc-advance-overrides/${id}`)),
  update: ((payload, id) => axiosIns.put(`accounting/acc-advance-overrides/${id}`, payload)),
  delete: (id => axiosIns.delete(`accounting/acc-advance-overrides/${id}`)),
  getChartOfAccounts: (() => axiosIns.get('accounting/coa-mapping/std-fee-coa')),
  priorityUpdate: (payload => axiosIns.post('accounting/acc-advance-overrides/custom-priority', payload)), */
}
